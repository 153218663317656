<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>
          {{ $t("Weekly report") }}
          <b-link :to="{ name: 'Reports' }">
            <b-icon-arrow-return-left />
          </b-link>
        </h1>
        <b-breadcrumb :items="br_items" />
        <h2>
          {{ repr }}
          <b-link @click="show_modal = true">
            <b-icon-gear />
          </b-link>
          <a :href="build_url(true, true)">
            <b-icon-download />
          </a>
        </h2>
        <b-modal v-model="show_modal" hide-footer hide-header>
          <weekly-report-form @ok="go_to" />
        </b-modal>
      </b-col>
    </b-row>
    <b-overlay :show="loading">
      <b-row v-if="!is_empty">
        <b-col style="overflow: auto">
          <b-table-simple>
            <b-tr>
              <b-th> #</b-th>
              <b-th v-for="line in lines_list" :key="line.uuid">
                {{ line.name }}
              </b-th>
            </b-tr>
            <b-tr v-for="cat in category_list" :key="cat.uuid">
              <b-td class="lines">
                {{ cat.name }}
              </b-td>
              <b-td v-for="line in lines_list" :key="line.uuid">
                <span v-if="get_data(cat, line)" class="line">
                  <nobr class="scope">{{ get_data(cat, line).avg_score }}</nobr>
                  <nobr class="pscope">{{
                    get_data(cat, line).avg_pscore
                  }}</nobr>
                </span>
              </b-td>
            </b-tr>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <h2>{{ $t("Nothing found") }}</h2>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>

<script>
import { merge, uniq, orderBy } from "lodash-es";
import { capitalyze, date_format, dict_to_search, to_query } from "../shared";
import WeeklyReportForm from "../components/WeeklyReportForm";
import { SERVER } from "../constants";
import { getISOWeek } from "date-fns";

export default {
  name: "WeeklyReport",
  components: { WeeklyReportForm },
  mounted() {
    this.load();
  },
  data() {
    return {
      resp: [],
      loading: false,
      show_modal: false,
    };
  },
  computed: {
    br_items() {
      return [
        { text: this.$t("Home"), to: { name: "Home" } },
        { text: this.$t("Reports"), to: { name: "Reports" } },
        { text: this.$t("Weekly report"), active: true },
      ];
    },
    repr() {
      let ret = "";
      if (this.$route.query.category) {
        const cat = this.$store.getters.category.find(
          (c) => c.pk == this.$route.query.category
        );
        if (cat) {
          ret += cat.name;
        }
      } else {
        ret += this.$t("All categories");
      }
      const per = this.$route.query.period;
      const per_name = this.$t(capitalyze(per));
      const d1 = new Date(this.$route.query.start);
      let period_repr = "";
      if (per == "week") {
        period_repr = getISOWeek(d1);
      } else if (per == "month") {
        period_repr = d1.getMonth() + 1;
      } else if (per == "day") {
        period_repr = date_format(d1);
      } else if (per == "period") {
        let d2 = new Date(this.$route.query.end);
        period_repr = `${date_format(d1)} - ${date_format(d2)}`;
      }

      ret += `, ${per_name} ${period_repr}`;

      return ret;
    },
    is_empty() {
      return this.resp.length == 0;
    },
    restored() {
      return this.resp.map((o) =>
        merge(o, {
          avg_score: o.avg_score.toFixed(0),
          avg_pscore: `${(100 * o.avg_pscore).toFixed(0)} % `,
        })
      );
    },
    category_list() {
      return uniq(this.restored.map((o) => o.category)).map((o) =>
        this.$store.getters.category.find((l) => l.pk == o)
      );
    },
    lines_list() {
      return orderBy(
        uniq(this.restored.map((l) => l.info)).map((o) =>
          this.$store.getters.line.find((l) => l.pk == o)
        ),
        "pk"
      );
    },
  },
  methods: {
    get_data(category, info) {
      return this.restored.find(
        (r) => r.category == category.pk && r.info == info.pk
      );
    },
    build_url(xls = false, with_server) {
      let data = merge({}, this.$route.query);
      if (xls) {
        data["frmt"] = "xlsx";
      }
      let query_str = dict_to_search(data);
      let ret = `api/reports/weekly/?${query_str}`;
      if (with_server) {
        ret = `${SERVER}/${ret}`;
      }
      return ret;
    },
    load() {
      this.loading = true;
      Promise.all([
        this.$store
          .dispatch("get", {
            url: this.build_url(),
          })
          .then((r) => (this.resp = r)),
        this.$store.dispatch("load_data", ["additional_data", "category"]),
      ]).then(() => (this.loading = false));
    },
    go_to(args) {
      this.show_modal = false;
      this.$router.push({ name: this.$route.name, query: to_query(args) });
      this.load();
    },
  },
};
</script>

<style lang='scss'>
@import "../static/scss/_variables";

.lines {
  background-color: lighten($blue, 20%);
}

.line {
  margin: -0.75em;
  display: block;
  $pad-size: 0.25em;

  .scope {
    display: block;
    width: 100%;
    padding: $pad-size $pad-size 0 $pad-size;
    background-color: lighten($blue, 40%);
  }

  .pscope {
    display: block;
    width: 100%;
    padding: 0 0.25em 0.25em 0.25em;
    background-color: $white;
  }
}
</style>
